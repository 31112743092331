import { useState, useEffect } from 'react';
import { Stack, MenuItem, Select, FormControl, Box, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const Lists = ({userAccessToken, currentEntity, roleEntities, spoListNameDic, currentListId, listitems, columns, getListItems}) => {
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [selectedList, setSelectedList] = useState(null);

    const handleEntityChange = async (entity) => {
        setSelectedEntity(entity);
        const listIdEnvName = `REACT_APP_PUBLIC_${selectedList}_ID_${entity}`;
        const listId = process.env[listIdEnvName]
        await getListItems(userAccessToken, listId, entity);
    };

    const handleListChange = async (listName) => {
        setSelectedList(listName);
        const listIdEnvName = `REACT_APP_PUBLIC_${listName}_ID_${selectedEntity}`;
        const listId = process.env[listIdEnvName]
        await getListItems(userAccessToken, listId, selectedEntity);
    };

    useEffect(() => {
        setSelectedEntity(currentEntity);
        setSelectedList(currentListId);
    }, [currentEntity, currentListId]);

    return (
        <>
            <Box width={'97%'}>
                <FormControl sx={{ marginBottom: 2, marginRight:5 }} size="small">
                {/* <InputLabel id="entity-label">Entity</InputLabel> */}
                <Select 
                    labelId="entity-label"
                    //label="Entity"
                    value={selectedEntity} 
                    onChange={(e) => handleEntityChange(e.target.value)}
                    disabled={roleEntities?.length === 1}
                >
                    {roleEntities?.map((entity) => (
                        <MenuItem key={entity} value={entity}>{entity}</MenuItem>
                    ))}
                </Select>
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }} size="small">
                {/* <InputLabel id="entity-label">Lists</InputLabel> */}
                <Select 
                    labelId="lists-label"
                    //label="Lists"
                    value={selectedList} 
                    onChange={(e) => handleListChange(e.target.value)}
                    disabled={spoListNameDic?.length === 1}
                >
                    {spoListNameDic?.map((listNamePair) => (
                        <MenuItem key={listNamePair.key} value={listNamePair.value}>{listNamePair.key}</MenuItem>
                    ))}
                </Select>
                </FormControl>
            </Box>
            <Paper sx={{ minWidth: 900, width: '97%', border: 0, }}>
                <DataGrid
                    rows={listitems || []}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                        columns: {
                            columnVisibilityModel: {
                                'id': false,
                                'Title': false,
                                '_ColorTag': false,
                                'ComplianceAssetId': false,
                                'LinkTitleNoMenu': false,
                                'ID': false,
                                'ContentType': false,
                                'Author': false,
                                'Editor': false,
                                '_UIVersionString': false,
                                'Attachments': false,
                                'Edit': false,
                                'DocIcon': false,
                                'ItemChildCount': false,
                                'FolderChildCount': false,
                                '_ComplianceFlags': false,
                                '_ComplianceTag': false,
                                '_ComplianceTagWrittenTime': false,
                                '_ComplianceTagUserId': false,
                                '_IsRecord': false,
                                'AppAuthor': false,
                                'AppEditor': false,
                                '@odata.etag': false,
                                'AuthorLookupId': false,
                                'EditorLookupId': false,
                                'AppAuthorLookupId': false,
                                'AppEditorLookupId': false,
                            },
                        },
                    }}
                    pageSizeOptions={[10,25,50]}
                    disableRowSelectionOnClick
                    components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            No Data Found.
                          </Stack>
                        )
                      }}
                />
            </Paper>
        </>
    );
};

export default Lists;