import { useState } from 'react';
import { Fragment } from 'react';
import { downloadFile, deleteFile, uploadFile, fetchFileShareLink } from './services/sharepoint';
import { Tooltip, IconButton, Snackbar, List, ListItem, ListItemText, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Breadcrumbs, Link, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

const SearchResult = ({ files, onSearch, employeeId, folderName, isAdmin, setLoading, folderConfidentialityMapping, currentUserEmail, currentUserEntity, userAccessToken, docLibName }) => {
  const [open, setOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');
  //const [folderPath, setFolderPath] = useState('');

  const handleClickOpen = (fileId, fileURL, fileName) => {
    setOpen(true);
    setSelectedFileId(fileId);
    setSelectedFileURL(fileURL);
    setSelectedFileName(fileName)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    handleClose();
    setLoading(true);
    const result = await deleteFile(selectedFileId, employeeId, folderName, userAccessToken, selectedFileURL, currentUserEntity, docLibName, true);
    //await onSearch(employeeId, currentUserEmail, folderName, folderConfidentialityMapping[folderName], false, currentUserEntity, userAccessToken); 
    await onSearch(employeeId, currentUserEmail, folderName, false, currentUserEntity, userAccessToken); 
    setLoading(false);
    if(result.success) {
      setSnackbarMessage(`File successfully deleted.`);
      setSnackbarOpen(true);
    }else{
      setSnackbarMessage(`Faile to delete file, please contact administrator.`);
      setSnackbarOpen(true);
    }
  };

  const handleDownload = async (fileId, fileName, fileURL) => {
    setLoading(true);
    await downloadFile(fileId, fileName, employeeId, folderName, userAccessToken, fileURL, currentUserEntity, docLibName, true);
    setLoading(false);
  };

  const handleUpload = async (fileName, file) => {
    setLoading(true);
    //setFolderPath(`${folderConfidentialityMapping[folderName]}/${employeeId}/${folderName}`);
    //await uploadFile(fileName, file, `${folderConfidentialityMapping[folderName]}/${employeeId}/${folderName}`, employeeId, folderName, userAccessToken, currentUserEntity);
    //await onSearch(employeeId, currentUserEmail, folderName, folderConfidentialityMapping[folderName], false, currentUserEntity, userAccessToken); 
    await uploadFile(fileName, file, `${employeeId}/${folderName}`, employeeId, folderName, userAccessToken, currentUserEntity, docLibName, true);
    await onSearch(employeeId, currentUserEmail, folderName, false, currentUserEntity, userAccessToken); 
    
    setLoading(false);

    setSnackbarMessage(`Successfully uploaded file: ${fileName}.`);
    setSnackbarOpen(true);
  };

  //const handleFolderLinkSearch = async (employeeId, folderName, confidentialityLevel) => {
  const handleFolderLinkSearch = async (employeeId, folderName) => {
    setLoading(true);
    //await onSearch(employeeId, currentUserEmail, folderName, confidentialityLevel, false, currentUserEntity, userAccessToken); 
    await onSearch(employeeId, currentUserEmail, folderName, false, currentUserEntity, userAccessToken); 
    setLoading(false);
  }

  const handleFileOpen = async (fileId) => {
    setLoading(true);
    const fileLink = await fetchFileShareLink(fileId, employeeId, userAccessToken, currentUserEntity, docLibName, folderName, true, selectedFileURL);
    window.open(fileLink, '_blank');
    setLoading(false);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const BreadcrumbsNav = () => (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="#" onClick={(e) => e.preventDefault()}>
        {employeeId}
      </Link>
      <Typography color="textPrimary">{folderName}</Typography>
    </Breadcrumbs>
  );

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const sortedFiles = files?.slice().sort((a, b) => new Date(b.lastModifiedDateTime) - new Date(a.lastModifiedDateTime));
  
  return (
    <>
      {/* <BreadcrumbsNav/> */}
      {isAdmin && (
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
        >
          Upload
          <VisuallyHiddenInput type="file" onChange={(e) => handleUpload(e.target.files[0].name, e.target.files[0])} />
        </Button>
      )}
      <List>
      { sortedFiles?.map(file => (
          <ListItem key={file.id} divider>
            {file.isFolder ? (
              <Link component="button" onClick={() => handleFolderLinkSearch(employeeId, file.name)}>
                {file.name}
              </Link>
            ) : (
              <>
                <ListItemText primary={file.URL} secondary={convertToLocalTime(file.lastModifiedDateTime)} />
                {/* <Button variant="contained" color="primary" onClick={() => handleDownload(file.id, file.name, file.URL)} sx={{ marginRight: 1 }}>
                  Download
                </Button> */}
                <Tooltip title="Open In Browser">
                  <IconButton
                    color="primary"
                    onClick={() => handleFileOpen(file.id)}
                    sx={{ marginRight: 1 }}
                  >
                    <OpenInBrowserIcon />
                  </IconButton>
                </Tooltip>

                {isAdmin && (
                  <Tooltip title="Delete">
                    <IconButton
                      color="secondary"
                      onClick={() => handleClickOpen(file.id, file.URL, file.Name)}
                    >
                      <DeleteForeverRoundedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </ListItem>
        ))
      } 
      </List>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item permanently?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} variant="contained" color="secondary" autoFocus>
            Delete
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleSnackbarClose}
            >
              <CloseIcon />
            </IconButton>
          </Fragment>
        }
      />
    </>
  );
}
function convertToLocalTime(utcDateString) {
  const date = new Date(utcDateString);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };
  return date.toLocaleString('zh-CN', options);
}

export default SearchResult;