import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', 
    },
    secondary: {
      main: '#b30000',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:focus': {
            borderColor: '#000000', // Black border for focused state
          },
        },
      },
      containedSecondary: {
        backgroundColor: '#b30000',
        '&:hover': {
          backgroundColor: '#b30000',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#000000', // Black border for focused state
            },
          },
        },
      },
    },
    // Adding customization for the MuiSelect component
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent', // Keeps the background transparent on focus
          },
        },
        icon: {
          color: '#000000', // Ensures the dropdown icon matches the black theme
        },
      },
    },
    // Customize the MenuItem as well
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#000000', // Black background on hover
            color: '#FFFFFF', // White text on hover
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        body {
          background-color: #FFFFFF; 
        }
      `,
    },
  },
});

export default theme;