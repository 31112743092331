import { useState, useEffect } from 'react';
import { Box, TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

export function SearchPanel({ onSearch, currentUserEmail, empId, folderConfidentialityMapping, currentEntity, userAccessToken, folderName }) {
    const [selectedFolder, setSelectedFolder] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        if (empId) {
            setEmployeeId(empId.toUpperCase());
        }
        else{
            setEmployeeId('');
        }
        if (folderName) {
            setSelectedFolder(folderName);
        }else{
            setSelectedFolder('');
        }
    }, [empId, folderName]);

    const handleSearchFolders = async() => {
        if (employeeId && !selectedFolder) {
          //await onSearch(employeeId, currentUserEmail, null, null, true, currentEntity, userAccessToken); 
          await onSearch(employeeId, currentUserEmail, null, true, currentEntity, userAccessToken); 
        }
    };
    const handleSearchClick = async() => {
        if (!employeeId) {
            setError(true);
        } else {
            setError(false);
            if (!selectedFolder) {
                handleSearchFolders();
                return;
            }
            //const confidentialityLevel = folderConfidentialityMapping[selectedFolder];
            //await onSearch(employeeId, currentUserEmail, selectedFolder, confidentialityLevel, false, currentEntity, userAccessToken);
            await onSearch(employeeId, currentUserEmail, selectedFolder, false, currentEntity, userAccessToken);
        }
    };

    return (
        <Box>
            <Box display="flex" flexDirection="row" mb={2}>
                <TextField 
                    required
                    label="Employee ID" 
                    variant="outlined" 
                    error={error}
                    value={employeeId} 
                    onChange={(e) => setEmployeeId(e.target.value.toUpperCase())}
                    sx={{ width: 250, height: 58, mr: 2 }} 
                />
                <FormControl sx={{ width: 250, height: 58 }}>
                    <InputLabel id="folder-type-label">Folder Type</InputLabel>
                    <Select 
                        labelId="folder-type-label"
                        label="Folder Type"
                        value={selectedFolder} 
                        onChange={(e) => setSelectedFolder(e.target.value)}
                    >
                        <MenuItem value=""><em>====SELECT====</em></MenuItem>
                        {Object.keys(folderConfidentialityMapping).map((folderName) => (
                            <MenuItem key={folderName} value={folderName}>{folderName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box mb={2}> {/* Add margin-bottom to create space between rows */}
                <Button variant="contained" onClick={handleSearchClick}>Search</Button>
            </Box>
        </Box>
    );
}