import axios from 'axios';

async function checkEmployeeExistence(employeeId, entity)
{
  try {
    const response = await axios.post('/fetch-employee-item', { 
      employeeId: employeeId,
      entity: entity,
    });
    return response.data === null ? false : true;
  }catch (error) {
    console.error(`Error fetching employee: ${employeeId} from server:`, error);
    return false;
  }
}

async function fetchFolderTypeMapping(userAccessToken, employeeId, entity) {
  try {
    const response = await axios.post('/fetch-folder-type-mapping', { 
      employeeId: employeeId,
      userAccessToken: userAccessToken, 
      entity: entity,
  });
    return response.data;
  }catch (error) {
    console.error('Error fetching folder type mapping from server:', error);
    return null;
  }
}

async function fetchListItems(userAccessToken, listId, entity) {
  try {
    const response = await axios.post('/fetch-list-items', { 
      listId: listId,
      userAccessToken: userAccessToken, 
      entity: entity,
  });
    return response.data;
  }catch (error) {
    console.error(`Error fetching items from list ${listId} server:`, error);
    return null;
  }
}

async function fetchFileShareLink(fileId, employeeId, userAccessToken, entity, docLibName, folderName, validateEmp, fileURL) {
  try {
    const response = await axios.post('/fetch-file-link', { 
        fileId: fileId,
        employeeId: employeeId,
        userAccessToken: userAccessToken,
        entity: entity,
        docLibName: docLibName,
        folderName: folderName,
        validateEmp: validateEmp,
        fileURL: fileURL,
  });
    return response.data;
  } catch (error) {
    console.error('Error fetching file share link from server:', error);
    return null;
  }
}

async function fetchFilesFromSharePoint(docLibName, folderPath, employeeId, folderName, userAccessToken, entity, validateEmp) {
  try {
    const response = await axios.post('/fetch-sp-files', { 
        folderPath: folderPath,
        employeeId: employeeId,
        folderName: folderName,
        userAccessToken: userAccessToken, 
        entity: entity,
        docLibName: docLibName,
        validateEmp: validateEmp,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching files from server:', error);
    return null;
  }
}

async function deleteFile(fileId, employeeId, folderName, userAccessToken, fileURL, entity, docLibName, validateEmp) {
  try {
    const response = await axios.post('/delete-file', { 
        fileId,
        employeeId,
        folderName,
        userAccessToken, 
        fileURL,
        entity,
        docLibName,
        validateEmp,
    });
    
    return response.data;

  } catch (error) {
    console.error("Delete Failed: ", error);
    
    if (error.response && error.response.data) {
      return error.response.data;
    }
    
    return {
      success: false,
      message: "Failed to delete file from server"
    };
  }
}

async function downloadFile(fileId, fileName, employeeId, folderName, userAccessToken, fileURL, entity, docLibName, validateEmp) {
  try {
    const response = await axios.post('/download-file', {
        fileId: fileId, 
        fileName: fileName, 
        employeeId: employeeId,
        folderName: folderName,
        userAccessToken: userAccessToken, 
        fileURL: fileURL,
        entity: entity,
        docLibName: docLibName,
        validateEmp: validateEmp,
    }, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName || 'download';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    return response.data;
  } catch (error) {
      console.error("Download Failed: ", error);
  }
}

async function uploadFile(fileName, file, sourceFolderPath, employeeId, folderName, userAccessToken, entity, docLibName, validateEmp) {
  try {
    const fileBuffer = await readFileAsBuffer(file);

    const response = await axios.post('/upload-file', { 
      fileBuffer: fileBuffer,
      fileName: fileName,
      sourceFolderPath: sourceFolderPath,
      employeeId: employeeId,
      folderName: folderName,
      userAccessToken: userAccessToken, 
      entity: entity,
      docLibName: docLibName,
      validateEmp: validateEmp,
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading file to server:', error);
    return null;
  }
}

async function updateFileName(docLibName, fileId, newFileName, entity, userEmail, userAccessToken) {
  try {
    const response = await axios.post('/update-file-name', { 
      fileId: fileId,
      newFileName: newFileName,
      entity: entity,
      userEmail: userEmail, 
      userAccessToken: userAccessToken,
      docLibName: docLibName,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating file name:', error);
    return null;
  }
}

function readFileAsBuffer(file) {
  return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

async function getCurrentUserInfo(accessToken) {
  const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch('https://graph.microsoft.com/v1.0/me', options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export {
  fetchFilesFromSharePoint,
  downloadFile,
  deleteFile,
  uploadFile,
  getCurrentUserInfo,
  fetchFolderTypeMapping,
  updateFileName,
  fetchFileShareLink,
  fetchListItems,
  checkEmployeeExistence,
};
