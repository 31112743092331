import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState, useEffect } from 'react';
import { loginRequest } from "./utils/authConfig";
import { useMsal } from "@azure/msal-react";
import { getCurrentUserInfo } from './services/sharepoint';


export default function AccountMenu() {
    const { instance, accounts } = useMsal();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
    setAnchorEl(null);
    };

    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        }).then(() => {
            setCurrentUser(null);
            localStorage.removeItem('msal.account');
        });
        handleClose();
    };

    useEffect(() => {
        const pageLoad = async () => {
            if (accounts.length > 0) {
                try {
                    const response = await instance.acquireTokenSilent({
                        ...loginRequest,
                        account: accounts[0],
                    });
                    const userInfo = await getCurrentUserInfo(response.accessToken);
                    setCurrentUser(userInfo);
                } catch (error) {
                    console.error("Error: ", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        pageLoad();
    }, [accounts, instance]);

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <List sx={{ '& .MuiTypography-root': { fontSize: '0.8rem' } }}>
                {isLoading ? (<ListItem></ListItem>) : (
                    <ListItem sx={{paddingRight: 0}}>
                        <ListItemText 
                        primary={`${currentUser.displayName}`}
                        secondary={`${currentUser.mail}`}
                        primaryTypographyProps={{ sx: { fontSize: '0.75rem' } }}
                        secondaryTypographyProps={{ sx: { color: '#808080', fontSize: '0.65rem' } }} />
                        <ListItemAvatar>
                            <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <MoreVertIcon sx={{color: 'white'}} />
                            </IconButton>
                            </Tooltip>
                        </ListItemAvatar>
                    </ListItem>
                )}
                </List>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                    },
                },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}