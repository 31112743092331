import { useState, useEffect, Fragment } from 'react';
import { Typography, CircularProgress, IconButton, Snackbar, ListItemText, ListItem, List, Tooltip, MenuItem, Select, FormControl, Box, Paper, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled } from '@mui/material';
import { checkEmployeeExistence, uploadFile, deleteFile, updateFileName, fetchFileShareLink } from './services/sharepoint';
import { DataGrid } from '@mui/x-data-grid';
import {
  Error as ErrorIcon,
  CheckCircle as CheckCircleIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { tooltipClasses } from '@mui/material/Tooltip';
import OpenInBrowserRoundedIcon from '@mui/icons-material/OpenInBrowserRounded';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

const UploadCentre = ({ onSearch, files, docLibName, userAccessToken, currentEntity, setLoading, currentUserEmail, roleEntities }) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openRename, setOpenRename] = useState(false);
  const [openExistence, setOpenExistence] = useState(false);
  const [allToBeUploadedFiles, setAllToBeUploadedFiles] = useState(null);
  const [toBeUploadedFiles, setToBeUploadedFiles] = useState(null);
  const [duplicatedFiles, setDuplicatedFiles] = useState(null);
  const [invalidFiles, setInvalidFiles] = useState(null);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const [fileOriginName, setFileOriginName] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [documentSeparator, setDocumentSeparator] = useState('');

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }}  placement="left" />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 350,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
      },
  }));

  const isUploading = uploadFiles.some(
    file => file.status === 'pending' || file.status === 'uploading'
  );

  const validateFileName = async (fileName) => {
    const tokens = fileName.split(documentSeparator);
    if (tokens.length !== 3) {
      return `Invalid file name, file name format should be: [EmployeeID]${documentSeparator}[Flex Id]${documentSeparator}[DocumenetName]`;
    }
    const employeeId = tokens[0];
    const doesEmpExist = await checkEmployeeExistence(employeeId, selectedEntity? selectedEntity: currentEntity)
    if (!doesEmpExist) {
      return "Invalid file name, employee id does not exist in Employee Mapping List.";
    }
    return null;
  };

  const processFile = async (file, index) => {
    const extension = file.name.split('.').pop().toLowerCase();

    const docLibFileWhiteListEnvName=`REACT_APP_PUBLIC_UPLOAD_CENTRE_UPLOADING_FILE_WHITELIST_${selectedEntity? selectedEntity: currentEntity}`;
    const docLibFileWhiteList = `${process.env[docLibFileWhiteListEnvName]}`;

    if (!docLibFileWhiteList.includes(extension)) {
      setUploadFiles(prev => prev.map((item, i) => 
        i === index ? { ...item, status: 'invalid_extension', error: `Invalid document extension, the valid document extension accepted are ${docLibFileWhiteList}` } : item
      ));
      return;
    }
  
    const fileNameError = await validateFileName(file.name);
    if (fileNameError) {
      setUploadFiles(prev => prev.map((item, i) => 
        i === index ? { ...item, status: 'invalid_name', error: fileNameError } : item
      ));
      return;
    }
  
    if (files.some(existingFile => existingFile.name === file.name)) {
      setUploadFiles(prev => prev.map((item, i) => 
        i === index ? { ...item, status: 'exists' } : item
      ));
      return;
    }
  
    setUploadFiles(prev => prev.map((item, i) => 
      i === index ? { ...item, status: 'uploading' } : item
    ));
  
    try {
      await uploadFile(file?.name, file, `${docLibName}/${file?.name}`, '', '', userAccessToken, selectedEntity? selectedEntity: currentEntity, docLibName, false);
      setUploadFiles(prev => prev.map((item, i) => 
        i === index ? { ...item, status: 'success' } : item
      ));
    } catch (error) {
      setUploadFiles(prev => prev.map((item, i) => 
        i === index ? { ...item, status: 'error', error: error.message } : item
      ));
    }
  };

  const handleUpload = async (selectedFiles) => {
    const filesArray = Array.from(selectedFiles).map(file => ({ file, status: 'pending' }));
    setUploadFiles(filesArray);
    setUploadDialogOpen(true);

    Promise.all(filesArray.map((fileInfo, index) => processFile(fileInfo.file, index)))
    .then(() => {
      onSearch(docLibName, selectedEntity? selectedEntity: currentEntity);
    })
    .then(() => {
      console.log('All files have been processed.');
    })
    .catch((error) => {
      console.error('Error occurred when uploading files:', error);
    });
  };

  const handleExistingFileUpload = async (file) => {
    const index = uploadFiles.findIndex(item => item.file === file);
    setUploadFiles(prev => prev.map((item, i) => 
      i === index ? { ...item, status: 'uploading' } : item
    ));

    try {
      await uploadFile(file?.name, file, `${docLibName}/${file?.name}`, '', '', userAccessToken, selectedEntity? selectedEntity: currentEntity, docLibName, false);
      setUploadFiles(prev => prev.map((item, i) => 
        i === index ? { ...item, status: 'success' } : item
      ));
      await onSearch(docLibName, selectedEntity? selectedEntity: currentEntity);
    } catch (error) {
      setUploadFiles(prev => prev.map((item, i) => 
        i === index ? { ...item, status: 'error', error: error.message } : item
      ));
    }
  };

  const renderFileStatus = (fileInfo) => {
    const { status, error, file } = fileInfo;
    switch (status) {
      case 'pending':
      case 'uploading':
        return <CircularProgress size={24} />;
      case 'invalid_extension':
        return (
          <Tooltip title={error}>
            <ErrorIcon color="error" />
          </Tooltip>
        );
      case 'invalid_name':
        return (
          <Tooltip title={error}>
            <ErrorIcon color="error" />
          </Tooltip>
        );
      case 'exists':
        return (
          <Tooltip title="This file already exists in the Document Library, click to continue uploading.">
            <CloudUploadIcon 
              color="primary" 
              style={{ cursor: 'pointer' }}
              onClick={() => handleExistingFileUpload(file)} 
            />
          </Tooltip>
        );
      case 'success':
        return (
          <Tooltip title="File uploaded successfully.">
            <CheckCircleIcon color="success" />
          </Tooltip>
      );
      case 'error':
        return (
          <Tooltip title={error}>
            <ErrorIcon color="error" />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  const handleFileExistence = async (uploadFiles) => {
    setLoading(true);
    const uploadFilesArray = Array.from(uploadFiles);

    const docLibFileWhiteListEnvName=`REACT_APP_PUBLIC_UPLOAD_CENTRE_UPLOADING_FILE_WHITELIST_${selectedEntity? selectedEntity: currentEntity}`;
    const docLibFileWhiteList = `${process.env[docLibFileWhiteListEnvName]}`;

    setAllToBeUploadedFiles(null);
    setToBeUploadedFiles(null);
    setDuplicatedFiles(null);
    setInvalidFiles(null);

    let existsFileArray = [];
    let allValidUploadFilesArray = [];
    let validUploadFilesArrayWithoutDuplicatedFile = [];
    let invalidExtesionFileArray = [];

    for (const uploadFile of uploadFilesArray) {
      //convert upload file extension
      let uploadFileExtention;
      const lastDotIndex = uploadFile.name.lastIndexOf('.');
      if (lastDotIndex === -1) {
        uploadFileExtention='';
      } else {
        uploadFileExtention = uploadFile.name.substring(lastDotIndex)
      }

      if(uploadFileExtention && docLibFileWhiteList.toUpperCase().includes(uploadFileExtention.toUpperCase())){
        if(files?.some(existFile => existFile.name.toUpperCase() === uploadFile.name.toUpperCase())){
          existsFileArray.push(uploadFile);
          allValidUploadFilesArray.push(uploadFile);
        }else{
          validUploadFilesArrayWithoutDuplicatedFile.push(uploadFile);
        }
      }else{
        invalidExtesionFileArray.push(uploadFile);
      }
    }
    
    setInvalidFiles(invalidExtesionFileArray);
    setDuplicatedFiles(existsFileArray);
    setToBeUploadedFiles(validUploadFilesArrayWithoutDuplicatedFile);
    setAllToBeUploadedFiles(allValidUploadFilesArray);

    if (existsFileArray?.length > 0 || invalidExtesionFileArray.length > 0) {
      setLoading(false);
      setOpenExistence(true);
      return;
    } else {
      await handleUpload(uploadFiles);
    }
    setLoading(false);
  };  

  const handleDialogUploadAll = async() => {
    setLoading(true);
    setOpenExistence(false);
    await handleUpload(allToBeUploadedFiles);
    setLoading(false);
  };

  const handleDialogUpload = async() => {
    setLoading(true);
    setOpenExistence(false);
    await handleUpload(toBeUploadedFiles);
    setLoading(false);
  };
  
  // const handleUpload = async (files) => {
  //   setLoading(true);

  //   for (const file of files) {
  //     await uploadFile(file?.name, file, `${docLibName}/${file?.name}`, '', '', userAccessToken, selectedEntity? selectedEntity: currentEntity, docLibName, false);
  //   }

  //   await onSearch(docLibName, selectedEntity? selectedEntity: currentEntity);
  //   setLoading(false);

  //   setSnackbarMessage(`Successfully uploaded ${files.length} file(s).`);
  //   setSnackbarOpen(true);
  // };

  const handleDelete = async () => {
    setLoading(true);
    setOpenDelete(false);
    const result = await deleteFile(selectedFileId, '','', userAccessToken, selectedFileURL, selectedEntity? selectedEntity: currentEntity, docLibName, false);
    await onSearch(docLibName, selectedEntity? selectedEntity: currentEntity);
    setLoading(false);
    if(result.success) {
      setSnackbarMessage(`Successfully deleted file: ${selectedFileName}.`);
      setSnackbarOpen(true);
    }else{
      setSnackbarMessage(`Faile to delete file: ${selectedFileName}, please contact administrator.`);
      setSnackbarOpen(true);
    }
  };

  const handleRename = async () => {
    setLoading(true);
    setOpenRename(false);
    const fileNewName = `${fileOriginName}${fileExtension}`;
    await updateFileName(docLibName, selectedFileId, fileNewName, selectedEntity? selectedEntity: currentEntity, currentUserEmail, userAccessToken)
    await onSearch(docLibName, selectedEntity? selectedEntity: currentEntity);
    setLoading(false);  
    setSnackbarMessage(`Successfully renamed file to: ${fileNewName}.`);
    setSnackbarOpen(true);
  };

  const handleRenameClickOpen = (fileId, fileURL, fileName) => {
    setOpenRename(true);
    setSelectedFileId(fileId);
    setSelectedFileURL(fileURL);
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) {
      setFileOriginName(fileName);
      setFileExtension('');
    } else {
      setFileOriginName(fileName.substring(0, lastDotIndex));
      setFileExtension(fileName.substring(lastDotIndex));
    }
  };

  const handleDeleteClickOpen = (fileId, fileURL, fileName) => {
    setOpenDelete(true);
    setSelectedFileId(fileId);
    setSelectedFileURL(fileURL);
    setSelectedFileName(fileName);
  };

  const handleFileOpen = async (fileId) => {
    setLoading(true);
    const fileLink = await fetchFileShareLink(fileId, '', userAccessToken, selectedEntity? selectedEntity: currentEntity, docLibName, '', false, selectedFileURL);
    window.open(fileLink, '_blank');
    setLoading(false);
  }

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleRenameClose = () => {
    setOpenRename(false);
  };

  const handleExistenceClose = () => {
    setOpenExistence(false);
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleEntityChange = async (entity) => {
    setLoading(true);
    setSelectedEntity(entity);
    await onSearch(docLibName, entity);
    setLoading(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  
  useEffect(() => {
    setDocumentSeparator(`${process.env.REACT_APP_PUBLIC_DOCUMENT_SEPARATOR}`);
    setSelectedEntity(currentEntity);
  }, [currentEntity]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" width={'97%'}>
        <FormControl sx={{ marginBottom: 2 }} size="small">
          {/* <InputLabel id="entity-label">Entity</InputLabel> */}
          <Select 
              labelId="entity-label"
              //label="Entity"
              value={selectedEntity} 
              onChange={(e) => handleEntityChange(e.target.value)}
              disabled={roleEntities?.length === 1}
          >
            {roleEntities?.map((entity) => (
                <MenuItem key={entity} value={entity}>{entity}</MenuItem>
            ))}
          </Select>
        </FormControl>
      <HtmlTooltip
        title={
          <Fragment>
            <Typography color="inherit">NOTE</Typography>
            {`Document name format should be [EmployeeID]${documentSeparator}[Flex Id]${documentSeparator}[DocumenetName]`}
            <br/>
            {`Flex Id: HIR, PER, JOB, CMP, LVE, MDL, BEN, DIS, TRG, APP, BON, OTH are acceptable.`}
            <br/>
            <em>{`e.g. P1234567A${documentSeparator}APP${documentSeparator}AppraisalFileName.pdf`}</em>
          </Fragment>
        }
      >
        <Button
          component="label"
          variant="contained"
          sx={{ marginBottom: 2 }}
        >
          Upload
          <VisuallyHiddenInput type="file" multiple onChange={(e) => handleUpload(e.target.files)} />
        </Button>
      </HtmlTooltip>
      </Box>
      <Paper sx={{ minWidth: 900, width: '97%', border: 0, }}>
        <DataGrid
          rows={files?.map(file => ({
            id: file.id,
            name: file.name,
            modified: convertToLocalTime(file.lastModifiedDateTime),
            modifiedBy: file.modifiedBy,
            URL: file.URL,
          }))}
          columns={[
            { field: 'name', headerName: 'File Name', flex: 2,},
            { 
              field: 'modified', 
              headerName: 'Modified', 
              flex: 1, 
              sortComparator: (v1, v2) => new Date(v1) - new Date(v2),
            },
            { field: 'modifiedBy', headerName: 'Modified By', flex: 2, },
            {
              field: 'actions',
              headerName: 'Actions',
              flex: 1.2,
              sortable: false, 
              filterable: false, 
              renderCell: (params) => (
                <>
                  <Tooltip title="Open In Browser">
                    <IconButton
                      color="primary"
                      onClick={() => handleFileOpen(params.row.id)}
                      sx={{ marginRight: 1 }}
                    >
                      <OpenInBrowserRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Rename">
                    <IconButton
                      color="primary"
                      onClick={() => handleRenameClickOpen(params.row.id, params.row.URL, params.row.name)}
                      sx={{ marginRight: 1 }}
                    >
                      <DriveFileRenameOutlineRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteClickOpen(params.row.id, params.row.URL, params.row.name)}
                    >
                      <DeleteForeverRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ),
            },
          ]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
            sorting: {
              sortModel: [{ field: 'modified', sort: 'desc' }],
            },
          }}
          pageSizeOptions={[10,25,50]}
          disableRowSelectionOnClick
        />
      </Paper>
      <Dialog
        open={openRename}
        onClose={handleRenameClose}
      >
        <DialogTitle>Rename</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                margin="dense"
                type="text"
                fullWidth
                value={fileOriginName}
                onChange={(e) => setFileOriginName(e.target.value)}
                inputProps={{ style: { width: '350px' } }}
                size="small"
                sx={{ marginRight: 0.5 }}
                />{fileExtension && <span>{fileExtension}</span>}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRename} variant="contained" color="secondary">
            Rename
          </Button>
          <Button onClick={handleRenameClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
      >
        <DialogTitle>Delete?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item permanently?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} variant="contained" color="secondary">
            Delete
          </Button>
          <Button onClick={handleDeleteClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openExistence}
        onClose={handleExistenceClose}
      >
        <DialogTitle>Upload</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {invalidFiles && invalidFiles?.length > 0 && <box>
              Below files' extesion are not allowed, will be ignored to upload:
              <List dense={true}>
                {invalidFiles?.map(invalidFile => (
                  <ListItem key={invalidFile.id}>
                    <ListItemText
                      primary={invalidFile.name}
                    />
                  </ListItem>))
                }
              </List>
            </box>}
            {duplicatedFiles && duplicatedFiles?.length > 0 && <box>
              Below files already exist in the document library, 
              please click "<span style={{ fontWeight: 'bold', color: 'black' }}>CONTINUE</span>" to upload all fils. 
              { toBeUploadedFiles && toBeUploadedFiles?.length > 0 && <span>Or click "<span style={{ fontWeight: 'bold', color: 'red' }}>IGNORE</span>" to upload all files except duplicated ones.</span> }
              <List dense={true}>
                {duplicatedFiles?.map(duplicatedFile => (
                  <ListItem key={duplicatedFile.id}>
                    <ListItemText
                      primary={duplicatedFile.name}
                    />
                  </ListItem>))
                }
              </List>
            </box>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          { allToBeUploadedFiles && allToBeUploadedFiles?.length > 0 && 
          <Tooltip title="Upload all files" placement="top">
            <Button onClick={handleDialogUploadAll} variant="contained" color="primary" sx={{ width: '70' }}>
              Continue
            </Button>
          </Tooltip>}
          { toBeUploadedFiles && toBeUploadedFiles?.length > 0 && 
          <Tooltip title="Ignore duplicated files and upload" placement="top">
            <Button onClick={handleDialogUpload} variant="contained" color="secondary" sx={{ width: '70' }}>
              Ignore
            </Button>
          </Tooltip>}
          <Button onClick={handleExistenceClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        open={uploadDialogOpen} 
        onClose={isUploading ? undefined : () => setUploadDialogOpen(false)}
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown={isUploading}
      >
        <DialogTitle>
          Upload Status
          {!isUploading && (
            <IconButton
              aria-label="close"
              onClick={() => setUploadDialogOpen(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please move mouse to status icon to check upload status details.
          </DialogContentText>
          <List>
            {uploadFiles.map((fileInfo, index) => (
              <ListItem key={index} divider>
                <ListItemText primary={fileInfo.file.name} />
                {renderFileStatus(fileInfo)}
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleSnackbarClose}
            >
              <CloseIcon />
            </IconButton>
          </Fragment>
        }
      />
    </>
  );
};

function convertToLocalTime(utcDateString) {
  const date = new Date(utcDateString);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };
  return date.toLocaleString('zh-CN', options);
}

export default UploadCentre;